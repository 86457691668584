import { createContext, useContext } from "react";
import { useHistory } from "react-router-dom";

const AppContext = createContext(null)

export const useApp = () => useContext(AppContext)

function AppProvider({children}) {

    const history = useHistory();

    const navigate = (route) => {
        history.push(route)
    }

    return (
        <AppContext.Provider value={{navigate}}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider;
