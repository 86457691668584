import { AnimatePresence, motion } from 'framer-motion'
import { MdOutlineKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp  } from "react-icons/md";
import { animation, bgUpVariants, bgDownVariants, upDownLoopLong, upDownLoopShort } from '../constants/animations';
import React from 'react'

function Background() {
  return (
    <div className='background'>
      <AnimatePresence>

        <motion.div {...animation} variants={bgDownVariants} className='line'>
          <motion.div {...upDownLoopLong} className="decor xs bg">background: r66 g66 b66</motion.div>
          <div className='xs claimer accent'>This website was designed and developed by Chris Eke</div>
        </motion.div>
        <motion.div {...animation} variants={bgUpVariants} className='line'>
            <MdOutlineKeyboardDoubleArrowDown  className='decor arrow_down'/>
        </motion.div>
        <motion.div {...animation} variants={bgDownVariants} className='line'>
          <motion.div {...upDownLoopShort} className="decor xs s_font">secondary font: jet brains</motion.div>
        </motion.div>
        <motion.div {...animation} variants={bgUpVariants} className='line'></motion.div>
        <motion.div {...animation} variants={bgDownVariants} className='line'>
          <motion.div {...upDownLoopShort} className="decor xs acc">accent: r108 g200 b223</motion.div>
        </motion.div>
        <motion.div {...animation} variants={bgUpVariants} className='line'>
          <MdOutlineKeyboardDoubleArrowUp  className='decor arrow_up'/>
        </motion.div>
        <motion.div {...animation} variants={bgDownVariants} className='line'>
          <motion.div {...upDownLoopLong} className="decor xs p_font">primary font: hanuman</motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default Background
