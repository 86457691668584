import React, { useState} from 'react';
import * as ROUTES from '../../constants/routes';
import { useApp } from '../../context/AppContext';
import { MdArrowForward } from 'react-icons/md';
import { softwareSkills, uiUXSkills } from '../../constants/data'
import { AnimatePresence, motion } from 'framer-motion';
import { animation, skillsVariants, introSlide, titleSlide } from '../../constants/animations';
import Button from '../../components/Button';
import './styles.scss';

function Home() {
  const { navigate } = useApp();
  const [ hovering, setHovering ] = useState(null)

  return (
    <div className='page'>
      <div className='page_container'>
        <div className="home">
        <motion.p
          {...animation}
          variants={introSlide}
          className='heading bold sm'>est 2024</motion.p>
        <motion.h1
        {...animation}
        variants={introSlide}
        className='xxl light'>
            <span>Hi, </span>
            <span className='bold accent accent_underline'>I'm Chris Eke.</span>
            <br/>
            <span> I'm an aspiring </span>
            <span
              onMouseEnter={() => setHovering('software_skills')}
              onMouseLeave={() => setHovering(null)}
              className='hover_container'
              >
                <span className='bold underline'>Software Developer</span>
                <AnimatePresence>
                {
                  hovering === 'software_skills' &&
                  <div className='software_skills'>
                    <motion.ul  {...animation} variants={skillsVariants} className='software_skills_list'>
                      {
                        softwareSkills.map(skill => (
                          <li key={skill} className='skill_item'>
                              <span className='xs'>{skill}</span>
                          </li>
                        ))
                      }
                    </motion.ul>
                  </div>
                }
                </AnimatePresence>
              </span>
            <span> and </span>
            <span
              onMouseEnter={() => setHovering('uiux_skills')}
              onMouseLeave={() => setHovering(null)}
              className='hover_container'
            >
                <span className='bold underline'>UI/UX Designer</span>
                <AnimatePresence>
                  {
                    hovering === 'uiux_skills' &&
                    <div className='uiux_skills'>
                    <motion.ul {...animation} variants={skillsVariants} className='uiux_skills_list'>
                      {
                        uiUXSkills.map(skill => (
                          <li key={skill} className='skill_item'>
                            <span className='xs'>{skill}</span>
                          </li>
                        ))
                      }
                    </motion.ul>
                    </div>
                  }
                </AnimatePresence>

            </span>
            <span> from Dallas, TX.</span>
        </motion.h1>
        <Button
          animations={{animation, introSlide}}
          styles='shift_left'
          action={() => navigate(ROUTES.PROJECTS)}
          label="See My Work"
          icon={MdArrowForward}
        />
        </div>
      </div>
    </div>
  )
}

export default Home
