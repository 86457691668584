import React from "react";

export const parseData = (data) => {
    const components = [];
    const regex = /<span.*?>(.*?)<\/span>/g;
    let match;
    let index = 0;

    while ((match = regex.exec(data)) !== null) {
      if (match.index > index) {
        const text = data.substring(index, match.index);
        components.push(<span key={index} className="normal">{text}</span>);
      }
      const spanContent = match[1];
      const hasBoldUnderline = /className=['"]bold underline_small['"]/.test(match[0]);
      components.push(
        <span
          key={index + 1}
          className={hasBoldUnderline ? "bold underline_small" : ""}
          dangerouslySetInnerHTML={{ __html: spanContent }}
        />
      );
      index = regex.lastIndex;
    }
    if (index < data.length) {
      const text = data.substring(index);
      components.push(<span key={index} className="normal">{text}</span>);
    }

    return components;
}
