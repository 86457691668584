import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import { projects } from '../../constants/data';
import { MdArrowForward  } from "react-icons/md";
import { parseData } from '../../helpers/parseBody';
import Button from '../../components/Button';
import { animation, skillsVariants, slideDown, fade, aboutBody } from '../../constants/animations';
import './styles.scss';
import VideoPlayer from '../../components/VideoPlayer';

function ProjectRouter() {
    const [ hovering, setHovering ] = useState(false);
    const [ index, setIndex ] = useState(1)
    const project = projects[index]

    const handleLink = (route) => {
        window.open(route, '_blank');
    }

    return (
        <div className='page'>
            <div className='page_container project_container'>
                <AnimatePresence mode='wait'>
                    <motion.div
                        key={project.id}
                        {...animation}
                        variants={fade}
                        className='project'
                    >
                        <div className='heading bold sm'>{project.tagline}</div>
                        <h2 className='xl light'>
                            <span>{project.type}: </span>
                            <span
                            onMouseEnter={() => setHovering(true)}
                            onMouseLeave={() => setHovering(false)}
                            className='hover_container'
                            >
                            <span className='bold accent accent_underline'>{project.name}</span>
                            <AnimatePresence>
                            {
                                hovering &&
                                <div className='tools'>
                                <motion.ul  {...animation} variants={skillsVariants} className='tools_list'>
                                    {
                                    project.tools.map(tool => (
                                        <li key={tool} className='tool'>
                                            <span className='xs'>{tool}</span>
                                        </li>
                                    ))
                                    }
                                </motion.ul>
                                </div>
                            }
                            </AnimatePresence>
                            </span>
                        </h2>
                        <p className='md light'>
                            {parseData(project.description)}
                        </p>
                        <div className='actions'>
                            <Button
                            icon={MdArrowForward}
                            label='View Live'
                            action={() => handleLink(project.live)}
                            />
                            <Button
                            icon={MdArrowForward}
                            label='View Code'
                            action={() => handleLink(project.github)}
                            />
                        </div>
                    </motion.div>
                    <motion.div
                        key={`${project.id}-pic`}
                        {...animation}
                        variants={fade}
                        className='project_pic'
                    >
                        <VideoPlayer src={project.video} />
                    </motion.div>
                </AnimatePresence>
                <motion.ul  {...animation} variants={aboutBody} className='indexer'>
                    {
                        Object.values(projects).map(project => (
                            <li
                                className={`bold sm ${index === project.id ? 'active_index' : 'nav_link'}`}
                                onClick={() => setIndex(project.id)}
                            >
                                0{project.id}
                            </li>
                        ))
                    }
                </motion.ul>
            </div>
        </div>
    )
}

export default ProjectRouter
