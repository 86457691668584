import React from 'react';
import { motion } from 'framer-motion'
import useParallaxController from '../../hooks/useParallaxController';
import { animation, aboutPic, aboutBody, slideUp, slideDown, fade } from '../../constants/animations';
import { bio } from '../../constants/data';
import './styles.scss';

function About() {
  return (
    <div className='page'>
      <div className='page_container about_container'>
        <motion.div
          {...animation}
          variants={fade}
          className='about_pic'
        />
        <motion.div
          {...animation}
          variants={fade}
          className="about"
        >
          <div className='heading bold sm'>Jul.26.91</div>
          <h2 className='xl light'>Bio: <span className="bold accent">An Introduction</span></h2>
          <p className='md light'>
            <span className='bold underline_small'>Greetings,</span>
            <span> my name is </span>
            <span className='bold underline_small'>Chris Eke</span>
            <span> and </span>
            <span className='bold underline_small'>I love to code.</span>
            <span> Well, actually </span>
            <span className='bold underline_small'>I love to create, and code is one way I do it.</span>
            <span>I have studied </span>
            <span className='bold underline_small'>front-end software development</span>
            <span> on my own for about </span>
            <span className='bold underline_small'>4 years,</span>
            <span> taken a </span>
            <span className='bold underline_small'>full-stack software development bootcamp,</span>
            <span> and have also studied </span>
            <span className='bold underline_small'>UI/UX design.</span>
            <span> I have an </span>
            <span className='bold underline_small'>obsession with detail</span>
            <span> and I really lean on </span>
            <span className='bold underline_small'>simplifying the user experience.</span>
            <span> I like building apps that revolve around my </span>
            <span className='bold underline_small'>interests in life</span>
            <span> which include </span>
            <span className='bold underline_small'>music, basketball, and art.</span>
            <span> Im an </span>
            <span className='bold underline_small'>old and quiet soul</span>
            <span> who enjoys the </span>
            <span className='bold underline_small'>simpler things</span>
            <span> in life, and </span>
            <span className='bold underline_small'>I appreciate you</span>
            <span> for stopping by.</span>
          </p>
        </motion.div>
      </div>
    </div>
  )
}

export default About
