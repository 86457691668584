import React from 'react'
import {motion} from 'framer-motion'
import './styles.scss';

function Button({action, label, icon: Icon, animations, styles}) {

  if (animations) {
    return (
      <motion.button {...animations?.animation} variants={animations?.introSlide} className={`button spacer ${styles}`} onClick={action}>
        <span className='sm'>{label}</span>
        <Icon className="button_icon" />
      </motion.button>
    )
  }

  return (
    <button  className={`button spacer ${styles}`} onClick={action}>
      <span className='sm'>{label}</span>
      <Icon className="button_icon" />
    </button>
  )
}

export default Button
