import React from 'react';
import { MdCopyright } from "react-icons/md";
import { animation, fadeDelay, slideStagger, linkSlide } from '../../constants/animations';
import { AnimatePresence, motion } from 'framer-motion'
import { socials } from '../../constants/data';
import './styles.scss';

function Footer() {
  return (
    <footer className='footer'>
        <motion.div
            {...animation}
            variants={fadeDelay}
            className='copyright'>
            {/* <MdCopyright/>
            <span className='bold sm'>ChrisEke.com</span> */}
        </motion.div>
        <motion.ul
            {...animation}
            variants={slideStagger}
            className='link_list'
        >
            {
                socials.map(social => (
                    <motion.li
                     variants={linkSlide}
                     className='link'
                     >
                        <a
                            className='nav_link bold sm'
                            href={social.href}
                            target='_blank'
                            rel="noreferrer"
                        >
                                {social.name}
                        </a>
                    </motion.li>
                ))
            }
        </motion.ul>
    </footer>
  )
}

export default Footer
