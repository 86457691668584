import React, { useState, useRef } from 'react';
import { MdPlayArrow, MdPause } from 'react-icons/md';

function VideoPlayer({src}) {
    const [isPlaying, setIsPlaying] = useState(true);
    const videoRef = useRef(null);

  const togglePlay = () => {
    setIsPlaying(prevState => !prevState);
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };
  return (
    <div className='video_player'>
      <video
        ref={videoRef}
        src={src}
        autoPlay
        loop={true}
        style={{ width: '100%', height: 'auto' }}
      />
      <div
        onClick={togglePlay}
        className='video_control'
        style={!isPlaying ? {backgroundColor: `rgba( 0, 0, 0, .25)`} : {backgroundColor: `rgba( 0, 0, 0, 0)`}}
        >
        {isPlaying ? <MdPause/> : <MdPlayArrow/>}
    </div>
    </div>
  )
}

export default VideoPlayer
