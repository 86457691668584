import logo from '../../assets/ce-logo.svg';
import React, { useState } from 'react'
import * as ROUTES from '../../constants/routes'
import { useApp } from '../../context/AppContext'
import { MdMenu, MdClose } from 'react-icons/md'
import { AnimatePresence, motion } from 'framer-motion';
import { animation, menuVariants, navLinkVariants, navLinksVariants, fadeDelay } from '../../constants/animations';
import './styles.scss';

function Header() {
    const [ menu, setMenu ] = useState(false);
    const { navigate } = useApp();

    const handleNavigation = (route) => {
        navigate(route)
        setMenu(false)
    }

    return (
        <header className='header'>
            <motion.div
                {...animation}
                variants={fadeDelay}
                className='logo_container'
            >
            <img
                src={logo}
                className='logo'
                onClick={() => handleNavigation(ROUTES.HOME)}
            />
            </motion.div>
            <motion.nav
                {...animation}
                variants={fadeDelay}
                className='nav'
            >
                <AnimatePresence>
                {
                    menu &&
                    <motion.ul
                        key="menu"
                        {...animation}
                        variants={navLinksVariants}
                        className='menu_list'
                    >
                        <motion.li
                            variants={navLinkVariants}
                            onClick={() => handleNavigation(ROUTES.ABOUT)}
                            className='nav_link bold sm'
                        >
                            About Me
                        </motion.li>
                        <motion.li
                            variants={navLinkVariants}
                            onClick={() => handleNavigation(ROUTES.PROJECTS)}
                            className='nav_link bold sm'
                        >
                            My Projects
                        </motion.li>
                    </motion.ul>
                }
                </AnimatePresence>
                <span className='menu' onClick={() => setMenu(!menu)}>
                    <AnimatePresence mode='wait'>
                        {
                            !menu ?
                            <motion.span
                                {...animation}
                                variants={menuVariants}
                                whileHover='hover'
                                key='open'
                                className='center'
                            >
                                <MdMenu />
                            </motion.span> :
                            <motion.span
                                {...animation}
                                variants={menuVariants}
                                whileHover='hover'
                                key='close'
                                className='center'
                            >
                                <MdClose />
                            </motion.span>
                        }
                    </AnimatePresence>
                </span>
            </motion.nav>
        </header>
    )
}

export default Header
