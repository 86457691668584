export const animation = {
    initial: "hidden",
    animate: "visible",
    exit: "exit"
}

export const sliderVariants = {
    visible: {
        opacity: 1,
        scale: 1
    },
    hidden: {
        opacity: 0,
        scale: .75
    }
}

export const bgUpVariants = {
    visible: {
        y: 0,
        transition: {
            delay: .5,
            duration: 1,
            // ease: "easeInOut",
        },
    },
    hidden: {
        y: 1080,
        transition: {
            duration: 4,
            // ease: "easeInOut",
        },
    }
}

export const bgDownVariants = {
    visible: {
        y: 0,
        transition: {
            delay: .5,
            duration: 1,
            // ease: "easeInOut",
        },
    },
    hidden: {
        y: -1080,
        transition: {
            duration: 4,
            // ease: "easeInOut",
        },
    }
}

export const menuVariants = {
    visible: {
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.1,
            ease: "easeInOut",
        },
    },
    hidden: {
        scale: 0,
        rotate: 45,
        transition: {
            duration: 0.1,
            ease: "easeInOut",
        },
    },
    hover: {
        scale: 1.1,
        transition: {
            duration: 0.1,
            ease: "easeInOut",
        },
    }
}


export const navLinksVariants = {
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
            when: "beforeChildren",
            staggerChildren: 0.05
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            when: "afterChildren"
        }
    }
  };


  export const navLinkVariants = {
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.2,
            ease: "easeOut",
        },
    },
    hidden: {
        opacity: 0,
        x: 100,
        transition: {
            duration: 0.3,
            ease: "easeOut",
        },
    },
}


export const skillsVariants = {
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
    }
}

export const fadeDelay = {
    visible: {
        opacity: 1,
        transition: {
            delay: 2.5,
            duration: .3,
        },
    },
    hidden: {
        opacity: 0,
        transition: {
            delay: 1,
            duration: 1,
        },
    }
}


export const slideStagger = {
    visible: {
        opacity: 1,
        transition: {
            delay: 1,
            duration: 0.3,
            when: "beforeChildren",
            staggerChildren: .3
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            when: "afterChildren"
        }
    }
  };

  export const linkSlide = {
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.2,
            ease: "easeInOut",
        },
    },
    hidden: {
        opacity: 0,
        x: 100,
        transition: {
            duration: 0.3,
            ease: "easeInOut",
        },
    },
  }


  export const introSlide = {
    visible: {
        opacity: 1,
        // y: 0,
        transition: {
            delay: .5,
            duration: 0.3,
            ease: "easeIn",
        },
    },
    hidden: {
        opacity: 0,
        // y: -50,
        transition: {
            duration: 0.2,
            ease: "easeInOut",
        },
    }
  }

  export const aboutPic = {
    visible: {
        opacity: 1,
        // x: 0,
        transition: {
            delay: .5,
            duration: 0.5,
            ease: "easeIn",
        },
    },
    hidden: {
        opacity: 0,
        // x: -200,
        transition: {
            duration: 0.2,
            ease: "easeOut",
        },
    },
  }

  export const aboutBody = {
    visible: {
        opacity: 1,
        // y: 0,
        transition: {
            duration: 0.5,
            ease: "easeIn",
        },
    },
    hidden: {
        opacity: 0,
        // y: 100,
        transition: {
            duration: 0.2,
            ease: "easeOut",
        },
    },
  }

  export const fade = {
    visible: {
        opacity: 1,
        transition: {
            duration: .5,
            ease: "easeIn",
        },
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: .5,
            ease: "easeOut",
        },
    },
    exit: {
        opacity: 0,
        transition: {
            duration: .5,
            ease: "easeOut",
        },
    }
  }

  export const slideDown = {
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "tween",
            // stiffness: 400,
            // dampimg: 40,
            duration: .3,
            ease: "linear",
        },
    },
    hidden: {
        opacity: 0,
        y: -100,
        transition: {
            type: "tween",
            duration: .2,
            ease: "linear",
        },
    },
    exit: {
        opacity: 0,
        y: 300,
        transition: {
            type: "tween",
            duration: .3,
            ease: "linear",
        },
    }
  }

  export const slideUp = {
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: .5,
            ease: "easeIn",
        },
    },
    hidden: {
        opacity: 0,
        y: 500,
        transition: {
            duration: .5,
            ease: "easeOut",
        },
    },
    exit: {
        opacity: 0,
        y: -500,
        transition: {
            duration: .5,
            ease: "easeOut",
        },
    }
  }

  export const upDownLoopLong = {
    initial: { y: -75, rotate: 90 },
    animate: {
        y: [-75, 125, -75],
        rotate: [90, 90, 90]
    },
    transition : {
        duration: 30,
        ease: "easeInOut",
        repeat: Infinity
    }
  }

  export const upDownLoopShort = {
    initial: { y: -25, rotate: 90 },
    animate: {
        y: [-25, 5, 50, -25],
        rotate: [90, 90, 90, 90]
    },
    transition : {
        duration: 20,
        ease: "easeInOut",
        repeat: Infinity
    }
  }
