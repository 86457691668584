import mezzo from '../assets/mezzo-logo.svg';
import backcourts from '../assets/backcourts-logo.svg';
import bustl from '../assets/bustl-logo.svg';
import mezzoImage from '../assets/mezzo_image.png';
import backcourtsImage from '../assets/backcourts_image.png';
import bustlImage from '../assets/bustl_image.png';
import bustlVideo from '../assets/bustl-vid.mp4'
import mezzoVideo from '../assets/mezzo-vid.mp4'
import backcourtsVideo from '../assets/backcourts-vid.mp4'

export const bio = `Greetings, my name is Chris Eke and I love to code. Well, actually I love to create, and code is one way I do it.I have studied front-end software development on my own for about 4 years, taken a full-stack software development bootcamp, and have also studied UI/UX design. I have an obsession with detail and I really lean on simplifying the user experience. I like building apps that revolve around my interests in life which include music, basketball, and art. Im an old and quiet soul who enjoys the simpler things in life, and I appreciate you for stopping by.`;

export const LINES = 7;

export const softwareSkills = [
    'React',
    'CSS',
    'HTML',
    'JS',
    'Node',
    'SQL',
    "Express",
    "AWS",
  ]

  export const uiUXSkills = [
    'Figma',
    'Illustrator',
    'After Effects'
  ]

  export const socials = [
    {
        name: "LinkedIn",
        href: "https://www.linkedin.com/in/iamchriseke"
    },
    {
        name: "GitHub",
        href: "https://github.com/ChrisEkeDev"
    },
    {
        name: "Dribbble",
        href: "https://dribbble.com/chriseke"
    },
    {
        name: "Contact",
        href: "mailto:iam@chriseke.com"
    }
  ]

  export const projects = {
     1:{
        id: 1,
        name: "Backcourts",
        tagline: "Who's Got Next",
        type: "Full Stack",
        logo: backcourts,
        image: backcourtsImage,
        video: backcourtsVideo,
        description: `
            <span className='bold underline_small'>Backcourts</span>
            <span> is a site for </span>
            <span className='bold underline_small'>sports enthusiasts</span>
            <span> and </span>
            <span className='bold underline_small'>athletes</span>
            <span> alike. Whether you are looking for a </span>
            <span className='bold underline_small'>quick pick up game</span>
            <span> during your travels after work, Backcourts has got you covered. It features </span>
            <span className='bold underline_small'>Google authentication</span>
            <span> for easy and secure registration, </span>
            <span className='bold underline_small'>team management</span>
            <span> and </span>
            <span className='bold underline_small'>session scheduling,</span>
            <span> as well as </span>
            <span className='bold underline_small'>live chats</span>
            <span> for instant communication.</span>
        `,
        tools: [
            "Node.js",
            "Postgres",
            "Sequelize",
            "Express",
            "React",
            "AWS",
            "WebSockets"
        ],
        github: "https://github.com/ChrisEkeDev/API-Project-Linkup",
        live: "https://linkup-api-jw4b.onrender.com/"
    },
    2: {
        id: 2,
        name: "Mezzo",
        tagline: "Music Matters",
        type: "Full Stack",
        logo: mezzo,
        image: mezzoImage,
        video: mezzoVideo,
        description: `
            <span className='bold underline_small'>Mezzo</span>
            <span> is an application I made for users and creators </span>
            <span className='bold underline_small'>to share and discover music.</span>
            <span> Create a profile and add an artist to the library to begin showcasing your skills by </span>
            <span className='bold underline_small'>uploading your music.</span>
            <span> Not a music maker and just curious? </span>
            <span className='bold underline_small'>Browse artists and music,</span>
            <span> make a list of your favorites and even </span>
            <span className='bold underline_small'>create playlists</span>
            <span> to listen anytime.</span>
        `,
        tools: [
            "Node.js",
            "Postgres",
            "Render",
            "Sequelize",
            "Express",
            "React",
            "AWS"
        ],
        github: "https://github.com/ChrisEkeDev/Mezzo",
        live: "https://mezzo.onrender.com/"
    },
    3: {
        id: 3,
        name: "Bustl",
        tagline: "Let's Get Busy",
        type: "Full Stack",
        logo: bustl,
        image: bustlImage,
        video: bustlVideo,
        description: `
            <span className='bold underline_small'>Bustl</span>
            <span>  is an app to help users discover and </span>
            <span className='bold underline_small'>schedule services with mobile businesses.</span>
            <span> Need a service on the go?  Search through a </span>
            <span className='bold underline_small'>categorized collection of businesses,</span>
            <span> view their list of provided services and </span>
            <span className='bold underline_small'>schedule appointments.</span>
            <span> Or, you can </span>
            <span className='bold underline_small'>post your businesses,</span>
            <span> set up your services, post your hours and start </span>
            <span className='bold underline_small'>getting busy.</span>
        `,
        tools: [
            "Node.js",
            "Postgres",
            "Render",
            "Sequelize",
            "Express",
            "React",
            "AWS"
        ],
        github: "https://github.com/ChrisEkeDev/Bustl",
        live: "https://bustl.onrender.com/"
    }
}
