import { AnimatePresence, motion } from 'framer-motion'
import { Switch, Route, useLocation } from 'react-router-dom'
import * as ROUTES from './constants/routes';
import * as VIEWS from './views';
import Header from './components/Header';
import Background from './components/Background';
import './App.scss';
import Footer from './components/Footer';



function App() {
  const location = useLocation();

  return (
    <main className='wrapper'>
      <Header/>
      <AnimatePresence mode='wait'>
        <Switch location={location} key={location.pathname}>
            <Route
              exact
              path={ROUTES.HOME}
              component={VIEWS.Home}
            />
            <Route
              exact
              path={ROUTES.ABOUT}
              component={VIEWS.About}
            />
            <Route
              exact
              path={ROUTES.PROJECTS}
              component={VIEWS.Projects}
            />
        </Switch>
      </AnimatePresence>
       <Footer/>
      <Background/>
    </main>
  );
}

export default App;
